import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import SEO from "../components/seo"

// import * as firebase from "firebase/app";
import firebase from "gatsby-plugin-firebase"

// import "firebase/firestore";
// import "firebase/storage";
// import "firebase/functions";
import {
  Button,
  CardActionArea,
  CircularProgress,
  Card,
  Typography,
} from "@material-ui/core"
import useStyles from "../styles"
import { arrayToCurrencyString } from "../utils"
import InfiniteScroll from "react-infinite-scroll-component"
import DialogDetail from "./dialogDetail"

const Katalog = props => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const lastVisible = React.useRef()
  const [loadingMore, setLoadingMore] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    product: {},
  })
  const classes = useStyles()

  useEffect(() => {
    const productsRef = firebase.firestore().collection("products")
    async function getProducts() {
      setLoading(true)
      setProducts([])
      // limit 2 products in
      const query = await productsRef
        .where("sembunyikan_produk", "==", false)
        .orderBy("sorting_number")
        .limit(13)
        .get()

      if (!query.empty) {
        const newProducts = await Promise.all(
          query.docs.map(async product => {
            const variants = await product.ref
              .collection("variants")
              .where(`harga_normal`, ">", 0)
              .get()
            return {
              id: product.id,
              ...product.data(),
              variants: variants.docs.map(variant => {
                return {
                  id: variant.id,
                  ...variant.data(),
                }
              }),
            }
          })
        )
        const filteredProducts = newProducts.filter(
          product => product.variants.length > 0
        )
        setProducts(products => [...products, ...filteredProducts])
        lastVisible.current = query.docs[query.docs.length - 1]
      }

      setLoading(false)
    }

    getProducts()
  }, [])

  const loadMoreProducts = async () => {
    if (lastVisible.current && loadingMore) {
      setLoadingMore(true)
      try {
        const productsRef = firebase.firestore().collection("products")
        const query = await productsRef
          .where("sembunyikan_produk", "==", false)
          .orderBy("sorting_number")
          .startAfter(lastVisible.current)
          .limit(12)
          .get()
        if (!query.empty) {
          const newProducts = await Promise.all(
            query.docs.map(async product => {
              const variants = await product.ref
                .collection("variants")
                .where(`harga_normal`, ">", 0)
                .get()
              return {
                id: product.id,
                ...product.data(),
                variants: variants.docs.map(variant => {
                  return {
                    id: variant.id,
                    ...variant.data(),
                  }
                }),
              }
            })
          )

          const filteredProducts = newProducts.filter(
            product => product.variants.length > 0
          )
          setProducts(products => [...products, ...filteredProducts])

          lastVisible.current = query.docs[query.docs.length - 1]
        } else {
          lastVisible.current = null
          setLoadingMore(false)
        }
      } catch (e) {}
    }
  }

  // if(loading){
  //   <CircularProgress />
  // }
  return (
    <div className="columns is-multiline">
      <SEO title="Katalog" url="https://tokorame.co.id/blog" />
      <Header />
      <div className="container">
        <div className="sections">
          <h3>Katalog Produk Tokorame</h3>
          {/* {loading ? (
            <CircularProgress />
          ) : ( */}
          {/* <div className="parent"> */}
          <InfiniteScroll
            dataLength={products.length}
            style={{ overflow: "hidden" }}
            next={loadMoreProducts}
            hasMore={loadingMore}
            scrollThreshold
            loader={
              <div style={{ marginLeft: "48%", marginTop: "5%" }}>
                <CircularProgress />
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center", paddingTop: 50 }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className={classes.productContent}>
              {products.map((data, index) => {
                const harga = data.variants.map(variant => variant.harga_normal)

                const harga_str = arrayToCurrencyString(harga)

                return (
                  <div className={classes.wrapper}>
                    <Card className={classes.card}>
                      <CardActionArea
                        onClick={() => {
                          setDialog({ open: true, product: data })
                        }}
                      >
                        {data.thumbnail?.length > 0 ? (
                          <img
                            loading="lazy"
                            src={data.thumbnail[0]}
                            alt={data.id}
                            className={classes.img}
                            // style={{
                            //   backgroundImage: `url(${data.thumbnail[0]})`,
                            // }}
                          />
                        ) : (
                          <img
                            loading="lazy"
                            src={data.thumbnail}
                            alt={data.id}
                            className={classes.img}
                            // style={{
                            //   backgroundImage: `url(${data.thumbnail})`,
                            // }}
                          />
                        )}
                      </CardActionArea>
                      <div className={classes.content}>
                        <Typography className={classes.title} component="p">
                          {data.nama}
                        </Typography>
                        <Typography className={classes.price} component="p">
                          {harga_str}
                        </Typography>
                      </div>
                      <div className={classes.btnWrap}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.btn}
                          onClick={() => {
                            setDialog({ open: true, product: data })
                          }}
                        >
                          Lihat Produk
                        </Button>
                      </div>
                    </Card>
                  </div>
                )
              })}
              {/* </div> */}
            </div>
            {/* )} */}
          </InfiniteScroll>
        </div>
      </div>
      <Footer />
      <DialogDetail
        open={dialog.open}
        handleClose={() => {
          setDialog({ open: false, product: {} })
        }}
        product={dialog.product}
      />
    </div>
  )
}

Katalog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query KatalogQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { page: { ne: true } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 300)
              id
              frontmatter {
                path
                blogtitle
                author
                blogimage
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Katalog data={data} count={count} />}
  />
)
